import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Services from "../components/structure/services"
import Layout from "../components/structure/layout"
import { Container } from "../components/styled/container"
import { Hero } from "../components/styled/hero"

const PageContent = styled.div`
  font-family: "Merriweather";
  font-size: 17px;
  line-height: 35px;
  margin: auto;
`

const Servicios = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Hero>
        <Container>
          <h1> {intl.formatMessage({ id: "header_services" })} </h1>
          <p>
          </p>
        </Container>
      </Hero>
      <Container>
        <Services />
      </Container>
    </Layout>
  )
}

export default Servicios
